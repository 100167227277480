"use client";

import { type ReactNode, useEffect, useState } from "react";
import { ThemeProvider, useTheme } from "next-themes";
import { Toaster } from "sonner";
import { LanguageProvider } from "@/lib/languages/language-provider";
import { TooltipProvider } from "@/ui/components";
import { KeyboardShortcutProvider } from "@/ui/hooks";

export default function Providers({ children }: { children: ReactNode }) {
  const { setTheme, theme } = useTheme(); // Use next-themes' useTheme hook
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // Avoid server-side rendering issues
    setIsMounted(true);

    // If the user has a stored theme preference, use it
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme('system'); // Apply the stored theme
    }
  }, [setTheme]);

  if (!isMounted) return null; // Prevent rendering during SSR

  return (
    <ThemeProvider
      attribute="class"
      enableSystem // Enable system theme detection
      defaultTheme="system" // Default to system theme if no preference
    >
      <LanguageProvider>
        <TooltipProvider>
          <KeyboardShortcutProvider>
            <Toaster closeButton className="pointer-events-auto" />
            {children}
          </KeyboardShortcutProvider>
        </TooltipProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
}
